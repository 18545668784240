import "./App.css";

function Home() {
  return (
    <div className="App-body pb-5">
      <header className="App-header">
        Olá, você não acessou uma rota existente :(
      </header>
    </div>
  );
}

export default Home;
